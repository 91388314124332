import React from 'react';
import styled, {css} from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';

import { BasicPageHeading } from '../components/PageHeading';
import { TextSection, SectionHeading, Declaration, UL, LI } from '../components/textComponents';
import { ContactLinks } from '../components/contact';
import { whenTablet } from '../utils/responsive';

import theme from './../data/theme';

import aboutPhoto from '../../static/assets/boulevard.jpg';
import earthToYouLogo from '../../static/assets/earthToYou_small.png';
import filtrexxLogo from '../../static/assets/filtrexx.png';

const earthToYouURL = 'http://www.earthtoyoulandscape.com/earthtoyoulandscape.com/Home.html';
const filtrexxURL = 'https://www.filtrexx.com/en';

const keywords = [
  'VLP',
  'Landscaping',
  'Landscaper',
  'Matt Vojticek',
  'Customer Service',
  'Northeast Ohio',
];

const page = {
  title: 'About Us',
  keywords: keywords,
  description: 'VLP Landscaping is a full-service landscaping company in Northeast Ohio.  We have been operating for over a decade, and have a reputation for exceeding customer expectations.',
};

const Wrapper = styled.div`
  width: ${ theme.contentWidth };
  max-width: ${ theme.maxContentWidth };
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
`;

const LeftContent = styled.div`
  flex: 1 0.5 0;
  min-width: 30rem;
  padding: 0;
  margin-right: 2rem;
  margin-bottom: 5rem;

  ${ whenTablet(css`
    margin-right: 4rem;
  `) };
`;

const RightContent = styled.div`
  flex: 1 10 0;
  min-width: 30rem;
  padding: 0;
`;

const SupplierLink = styled.a`
  display: inline-block;
  margin: 2rem 0;
  margin-right: 2rem;
  width: 25rem;
  height: auto;
  object-fit: contain;
  vertical-align: middle;

  &:last-of-type {
    margin-right: 0;
  }

`;

const SupplierImg = styled.img`
  margin: 0;
`;

export default () => (
  <Layout>
    <SEO title={page.title} keywords={page.keywords}/>
    <BasicPageHeading page={page}/>
    <Wrapper>
      <LeftContent>
        <TextSection>
          <Declaration>
            VLP Landscaping is a full-service, experienced landscaping company. VLP offers cost-effective landscaping solutions for both residential and commercial properties.
          </Declaration>
          <Declaration>
            Founded in 2008 by Matt Vojticek, VLP Landscaping has built a reputation for meeting and exceeding expectations through quality workmanship and outstanding customer service for landscape projects of all sizes.
          </Declaration>
          <Declaration>
            VLP Landscaping has a strong desire to deliver the best results possible for our clients.
          </Declaration>
        </TextSection>

        <TextSection css={`
          margin-top: 4rem;
          padding-top: 4rem;
        `}>
          <SectionHeading>Local Suppliers</SectionHeading>
          <Declaration>We source our landscaping materials and erosion control products from local NE Ohio businesses.</Declaration>
          <SupplierLink href={earthToYouURL} target="_blank"><SupplierImg src={earthToYouLogo} alt="Earth To You"/></SupplierLink>
          <SupplierLink href={filtrexxURL} target="_blank"><SupplierImg src={filtrexxLogo} alt="filtrexx"/></SupplierLink>
        </TextSection>

        <TextSection id="contact" css={`
          margin-top: 4rem;
          padding-top: 4rem;
        `}>
          <SectionHeading>Contact Us</SectionHeading>
          <Declaration>Want to get in touch?  Here's how you can reach us:</Declaration>
          <ContactLinks linkColor={theme.colors.primary}/>
        </TextSection>
      </LeftContent>
      <RightContent>
        <img src={aboutPhoto} alt="Trees planted in mulch beds in median."/>
      </RightContent>
    </Wrapper>
  </Layout>
);
